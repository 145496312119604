import React from "react";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import NumberFormat from "react-number-format";
import TextField from "@material-ui/core/TextField";
import Button from "components/CustomButtons/Button";
const useStyles = makeStyles((theme) => ({
  field: {
    height: "55px",
    marginTop: 20,
    padding: 15,
    paddingTop: 0,
    borderRadius: 20,
    color: "white",
    border: '2px solid #6171D1',
    backgroundColor: "#080B1D",
    boxShadow: "10px 10px 10px 1px rgba(0, 0, 0, 0.5)",
  },
  inputRoot: {
    color: "white",
    fontSize: 20,
    padding: 11,
  },
  showDetail: {
    alignItems: "center",
    marginTop: 5,
    marginBottom: 10,
    justifyContent: "space-around",
    width: "100%",
    color: "black",
    backgroundColor: "transparent",
    fontWeight: 600,
    "& .MuiInputBase-root": {
      color: "black",
    },
    "& fieldset": { color: "black" },
    "& .MuiInputBase-root.Mui-disabled": {
      backgroundColor: "transparent",
      color: "black",
      border: `none`,
      "& fieldset": {
        border: `none`,
      },
    },
    outline: "none",
  },
  hovered: {
    "& fieldset": {},
  },
  focused: {
    "& fieldset": {},
  },
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      style={{ fontSize: "20px", padding: 11,color:"white" }}
      getInputRef={inputRef}
      allowLeadingZeros={false}
      allowNegative={false}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
    />
  );
}

export default function CustomOutlinedInput(props) {
  const commonStyle = useStyles();
  const commonClasses = {
    root: commonStyle.showDetail,
  };

  const { classes, disabled, setMax, text, availabletext, placeholder,  } = props;
  const filteredProps = _.omit(props, ["setMax", "startAdornment"]);

  if (!setMax) {
    return (
      <div>
       
        <div className={commonStyle.field}>
          <TextField
            {...filteredProps}
            placeholder={placeholder || "0"}
            classes={Object.assign({}, commonClasses, classes)}
            InputProps={{
              classes: {
                input: commonStyle.inputRoot,
              },
              startAdornment: props.startAdornment,
              disableUnderline: true,
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={commonStyle.inputAdornment}
                ></InputAdornment>
              ),
            }}
          />
        </div>
        <div style={{textAlign:"right", marginTop:10}}>
          <span style={{ fontWeight: 600, marginTop: 15 }}>
            {availabletext}
          </span>
        </div>
      </div>
    );
  } else {
    return (
      <div >
       
        <div className={commonStyle.field}>
          <TextField
            {...filteredProps}
            placeholder={placeholder || "0"}
            classes={Object.assign({}, commonClasses, classes)}
            color="secondary"
            InputProps={{
              inputComponent: NumberFormatCustom,
              startAdornment: (
                <InputAdornment
                position="start"
                className={commonStyle.inputAdornment}
              >{props.startAdornment}</InputAdornment>),

              disableUnderline: true,
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={commonStyle.inputAdornment}
                >
                  {setMax && <Button
                    onClick={setMax}
                    color="text"
                    size="sm"
                    disabled={disabled}
                  >
                    MAX
                  </Button>}
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div style={{textAlign:"right", marginTop:10}}>
          <span style={{ fontWeight: 600, marginTop: 15 }}>
            {availabletext}
          </span>
        </div>
      </div>
    );
  }
}

CustomOutlinedInput.defaultProps = {
  fullWidth: true,
};

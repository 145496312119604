import React, { memo, useEffect } from "react";
import { useFetchPrice } from "../../price/fetchPrice";
import { useConnectWallet } from "features/home/redux/connectWallet";
import { tokens } from "features/configure";
import { LineChart, Line,Tooltip,XAxis } from 'recharts';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import _ from "lodash"
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import InfoIcon from '@material-ui/icons/Info';
import moment from "moment";
const PriceChart = ({label}) => {
  const theme = useTheme();
  const { web3 } = useConnectWallet();
  const { chart, priceDatas } = useFetchPrice();
  const chartData = _.map(chart,(data)=>{
    return {timestamp: data[0],price:data[1]}
  })
  
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="card" style={{opacity:0.7, padding:5}}>
           <p className="tooltipDesc">{`${moment(payload[0]?.payload?.timestamp).format('YYYY/MM/DD')}`}</p>
         <p className="tooltipLabel">{`$${payload[0].payload?.price.toFixed(4)}`}</p>
        
        </div>
      )
     }
  
    return null
  }
  const change = chartData && chartData.length>0?
  parseFloat((chartData[chartData.length-1].price-chartData[chartData.length-2].price)*100/chartData[chartData.length-2].price).toFixed(2):0

  let singleTokenPrice = priceDatas ? priceDatas[tokens.basicRewardAddress] : 0;
  return (
    <div style={{margin:"0px 0"}}>
      <span style={{float:"right", color:change >= 0?"#0DC599":"red",backgroundColor:"rgba(13, 197, 153, 0.1)", borderRadius:20, padding:'3px 5px'}}>
        {change >= 0?<ArrowDropUpIcon style={{ verticalAlign:"middle"}}/>:<ArrowDropDownIcon style={{ verticalAlign:"middle"}}/>}{change}% (24H)</span>
      <div className="cardLgValue">${parseFloat(singleTokenPrice).toFixed(2)}</div>
      <LineChart width={280} height={123} data={chartData}>
        
        <Tooltip content={<CustomTooltip />}/>
        <Line type="monotone" dataKey="price" stroke="#8884d8" strokeWidth={2}/>
      </LineChart>
      
    </div>
  );
};

export default memo(PriceChart);

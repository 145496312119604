import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  FETCH_LEADERBOARD_RANK_BEGIN,
  FETCH_LEADERBOARD_RANK_SUCCESS,
  FETCH_LEADERBOARD_RANK_FAILURE,
} from "./constants";
import {
  apiUrl
} from "../../configure";
import axios from "axios";

export function fetchLeaderboardRank({ name, timestamp, search, tabValue }) {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_LEADERBOARD_RANK_BEGIN,
    });

    const promise = new Promise(async (resolve, reject) => {
      try {
        let result = await axios.get(`${apiUrl}/api/users/leaderboard${tabValue==0?"/daily":""}?name=${name}&timestamp=${timestamp}&address=${search}`);
        dispatch({
          type: FETCH_LEADERBOARD_RANK_SUCCESS,
          data: {users: result.data}
        });
        resolve();
      }
      catch(error) {
          dispatch({
            type: FETCH_LEADERBOARD_RANK_FAILURE,
          });
          return reject(error.message || error);
      }
    });

    return promise;
  };
}

export function useFetchLeaderboardRank() {
  const dispatch = useDispatch();

  const { rank, fetchLeaderboardRankPending } = useSelector(
    (state) => ({
      fetchLeaderboardRankPending: state.leaderboard.fetchLeaderboardRankPending,
      rank: state.leaderboard.rank,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchLeaderboardRank(data));
    },
    [dispatch]
  );

  return {
    rank,
    fetchLeaderboardRank: boundAction,
    fetchLeaderboardRankPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FETCH_LEADERBOARD_RANK_BEGIN:
      return {
        ...state,
        fetchLeaderboardRankPending: true,
      };

    case FETCH_LEADERBOARD_RANK_SUCCESS:      
      return {
        ...state,
        rank: action.data,
        fetchLeaderboardRankPending: false,
      };

    case FETCH_LEADERBOARD_RANK_FAILURE:
      return {
        ...state,
        fetchLeaderboardRankPending: false,
      };

    default:
      return state;
  }
}
